
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    /* eslint-disable no-useless-escape */
import { ReactNode } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import * as Sentry from '@sentry/nextjs';
import { once } from 'lodash';
import { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import useTranslation from 'next-translate/useTranslation';
import { ErrorFallback } from 'components/Error/ErrorFallback';
import ChooseMultipleWinnersProvider from 'providers/ChooseMultipleWinnersProvider';
import CustomApolloProvider from 'providers/CustomApolloProvider';
import { GlobalToasts } from 'providers/GlobalToasts';
import LoadingProvider from 'providers/LoadingProvider';
import MultipleBidsProvider from 'providers/MultipleBidsProvider';
import RegionProvider from 'providers/RegionProvider';
import UserProvider from 'providers/UserProvider';
import { theme } from 'theme';
import { Fonts } from 'theme/Fonts';
import { YupConfig } from 'yup.config';
const initialize = once(() => {
    YupConfig.configure();
});
const App = ({ Component, pageProps }: AppProps) => {
    initialize();
    const { t } = useTranslation('common');
    const getLayout = Component.getLayout || ((page: ReactNode) => page);
    const pageTitle = `EzTenda | ${t('VENUE_PAGE_TEXT5')}`;
    return (<>
      <Head>
        <title>{pageTitle}</title>
      </Head>
      <ChakraProvider theme={theme}>
        <GlobalToasts>
          <Fonts />
          <CustomApolloProvider pageProps={pageProps}>
            <UserProvider>
              {/*
        TODO: rework multiple bids context to general store if needed
      */}
              <RegionProvider>
                <LoadingProvider>
                  <MultipleBidsProvider>
                    <ChooseMultipleWinnersProvider>
                      {/* Google Tag Manager Datalayer Initialization */}
                      <Script id="datalayer" dangerouslySetInnerHTML={{
            __html: `
                        window.dataLayer = window.dataLayer || [];
                        // Any window.dataLayer.push calls known at the time the page is rendered are placed here
                      `,
        }}/>
                      {process.env.NEXT_PUBLIC_ENVIRONMENT_NAME ===
            'production' && (<>
                          {/* GOOGLE TAG ANALYTICS */}
                          <Script id="gtm-analytics" strategy="afterInteractive" dangerouslySetInnerHTML={{
                __html: `
                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','GTM-P5LL5H3');
                    `,
            }}/>
                          <noscript 
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
                __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-P5LL5H3"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
            }}/>
                        </>)}
                      {process.env.NEXT_PUBLIC_ENVIRONMENT_NAME ===
            'production' && (<Script id="sopro-plugin" strategy="afterInteractive" dangerouslySetInnerHTML={{
                __html: `
                      (function (o, u, t, b, a, s, e) {
                        window[b] = window[b] || {}; (e = e || [])['key'] = o; e['__obr'] = u.location.href; a = [];
                        u.location.search.replace('?', '').split('&').forEach(function (q) { if (q.startsWith(b) || q.startsWith('_obid')) e[q.split('=')[0]] = q.split('=')[1]; });
                        e['_obid'] = e['_obid'] || (u.cookie.match(/(^|;)\s*_obid\s*=\s*([^;]+)/) || []).pop() || 0;
                        for (k in e) { if (e.hasOwnProperty(k)) a.push(encodeURIComponent(k) + '=' + encodeURIComponent(e[k])); }
                        s = u.createElement('script'); s.src = t + '?' + a.join('&'); u.head.appendChild(s);
                    })('b84d93aa-9622-4246-a065-77148c90b2ec', document, 'https://plugin.sopro.io/hq.js', 'outbase');
                    `,
            }}/>)}
                      <Sentry.ErrorBoundary 
    // eslint-disable-next-line react/no-unstable-nested-components
    fallback={({ resetError }) => (<ErrorFallback resetError={resetError}/>)}>
                        {getLayout(<Component {...pageProps}/>)}
                      </Sentry.ErrorBoundary>
                    </ChooseMultipleWinnersProvider>
                  </MultipleBidsProvider>
                </LoadingProvider>
              </RegionProvider>
            </UserProvider>
          </CustomApolloProvider>
        </GlobalToasts>
      </ChakraProvider>
    </>);
};
const __Next_Translate__Page__1924ea8a93c__ = App;

    export default __appWithI18n(__Next_Translate__Page__1924ea8a93c__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  